@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_hero_home {
    margin: 32px 0;

    .big {
        top: 0;
        left: -180px;

        @include breakpoint(xlarge) {
            left: -330px;
        }
    }

    .small {
        right: 0;
        bottom: 125px;
    }

    .grid-container.full {
        padding-left: calc(map-get($grid-column-gutter, small) / 2);
        padding-right: calc(map-get($grid-column-gutter, small) / 2);

        @include breakpoint(xlarge) {
            padding-left: map-get($grid-column-gutter, xlarge);
            padding-right: map-get($grid-column-gutter, xlarge);
        }
    }

    .title {
        margin-bottom: clamp(-12%, 640px, -200px);
        position: relative;
        z-index: 5;

        @include breakpoint(small only) {
            padding-right: 12px;
            padding-left: 12px;
        }
    }

    image {
        position: relative;
    }

    picture,
    video {
        aspect-ratio: 4 / 3;
        position: relative;
        display: block;

        @include breakpoint(large) {
            aspect-ratio: 16 / 9;
        }
    }

    @include breakpoint(small only) {
        picture {
            padding-bottom: 75%;
        }
    }

    .has-video,
    .image.has_overlay {
        overflow: hidden;
    }

    video {
        width: 100%;
        object-fit: cover;
    }

    .play-button {
        transform: none;
        left: 12px;
        z-index: 5;

        @include breakpoint(large down) {
            top: unset;
            bottom: 24px;
        }

        @include breakpoint(large) {
            left: 24px;
        }
    }

    picture img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .text {
        margin-top: 24px;
        position: relative;
        z-index: 5;

        @include breakpoint(small only) {
            padding-right: 12px;
            padding-left: 12px;
        }

        @include breakpoint(medium) {
            margin-top: 32px;
        }

        @include breakpoint(xlarge) {
            margin-top: -60px;
        }
    }

    .buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }
}

;@import "sass-embedded-legacy-load-done:2015";