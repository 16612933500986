@import "../../../src/scss/themes/tergos/abstracts/custom_variables";
@import "_default/block_hero_home.scss";

.block_hero_home {
    picture:before,
    picture:after,
    .image.has_overlay:before,
    .has-video:before,
    .has-video:after {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        display: block;
        opacity: 0.65;
    }

    .has-video:before,
    picture:before {
        background: $color-overlay-multiply;
        mix-blend-mode: multiply;
        z-index: 2;
    }

    .has-video:after,
    picture:after {
        background: $color-overlay-difference;
        mix-blend-mode: difference;
        z-index: 3;
    }

    .image.has_overlay:before {
        background: $color-overlay-color;
        mix-blend-mode: overlay;
        z-index: 4;
    }

    .has-video:before,
    .has-video:after,
    .image.has_overlay:before,
    .image.has_overlay:after {
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        top: -1px;
        left: -1px;
    }
}
